<template>
  <div>
    <div class="form-group">
      <label>{{ $t('Naam') }}</label>
      <input type="text" class="form-control" v-model="survey.name" :class="{ 'is-invalid' : errors['name'] }">
      <span v-if="errors['name']" class="invalid-feedback">
          <strong>{{ errors['name'][0] }}</strong>
      </span>
    </div>

    <div class="form-group">
      <label>{{ $t('Omschrijving') }}</label>
      <textarea class="form-control" v-model="survey.description" :class="{ 'is-invalid' : errors['description'] }"></textarea>
      <span v-if="errors['description']" class="invalid-feedback">
          <strong>{{ errors['description'][0] }}</strong>
      </span>
    </div>

    <div class="form-group">
      <label>{{ $t('Tekst na verzenden') }}</label>
      <textarea class="form-control" v-model="survey.finish_text" :class="{ 'is-invalid' : errors['finish_text'] }"></textarea>
      <span v-if="errors['finish_text']" class="invalid-feedback">
          <strong>{{ errors['finish_text'][0] }}</strong>
      </span>
    </div>


    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="1" v-model="survey.active" id="active" :class="{ 'is-invalid' : errors['active'] }">
      <label class="form-check-label" for="active">
        {{  $t('Actief') }}
      </label>

      <span v-if="errors['active']" class="invalid-feedback">
          <strong>{{ errors['active'][0] }}</strong>
      </span>
    </div>


    <div class="form-group mt-3">
      <label>{{ $t('Vragen') }}</label>

      <draggable :list="survey.questions" class="list-group">
        <div class="list-group-item p-0 d-flex align-items-center align-content-stretch" v-for="(question, index) in survey.questions">
          <div class="border-right d-flex h-100 p-3 icon">
            <template v-if="question.type === 'rate'">
              <i class="fas fa-star-half-alt"></i>
            </template>
            <template v-if="question.type === 'textarea'">
              <i class="fas fa-align-left"></i>
            </template>
            <template v-if="question.type === 'text'">
              <i class="fas fa-minus"></i>
            </template>
          </div>
          <div class="h-100 p-3 flex-grow-1">
            <input type="text" v-model="question.title" class="form-control" placeholder="Titel"  :class="{ 'is-invalid' : errors['questions.' + index + '.title'] }">
            <span v-if="errors['questions.' + index + '.title']" class="invalid-feedback">
                <strong>{{ errors['questions.' + index + '.title'][0] }}</strong>
            </span>

            <textarea class="form-control" v-model="question.description" placeholder="Korte omschrijving"></textarea>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="1" v-model="question.required" :id="'question-required-' + index">
              <label class="form-check-label" :for="'question-required-' + index">
                Verplicht veld
              </label>
            </div>

          </div>
          <div class="border-left h-100 p-3 icon text-center ">
            <i class="fas fa-grip-vertical cursor-move"></i>
            <i class="fas fa-trash cursor-pointer" @click="remove(index)"></i>
          </div>
        </div>
      </draggable>

      <small v-if="errors['questions']" class="text-danger">
          <strong>{{ errors['questions'][0] }}</strong>
      </small>

      <div class="my-2">
        <a href="#" class="btn btn-link" @click.prevent="create('rate')">
          <i class="fas fa-star-half-alt"></i> {{ $t('Nieuw cijfer element')}}
        </a>
        <a href="#" class="btn btn-link" @click.prevent="create('text')">
          <i class="fas fa-minus"></i> {{ $t('Nieuw vrij veld')}}
        </a>
        <a href="#" class="btn btn-link" @click.prevent="create('textarea')">
          <i class="fas fa-align-left"></i> {{ $t('Nieuw tekst veld')}}
        </a>
      </div>

      <button type="button" class="btn btn-primary" @click="submit">
        <template v-if="survey.id">
          {{ $t('Bewerken') }}
        </template>
        <template v-else>
          {{ $t('Toevoegen') }}
        </template>
      </button>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Base from '../../../Base';

export default {
  name: 'AdminSurveyForm',

  components: {
    draggable,
  },

  mixins: [ Base ],

  props: ['survey-data'],
  data() {
    return {
      survey: {
        questions: [],
      },
      errors: [],
    }
  },
  mounted() {
    if (this.surveyData) {
      this.survey = Object.assign({}, this.surveyData);
    }
  },

  methods: {
    create(type) {
      this.survey.questions.push({
        type,
      });
    },

    remove(index) {
      this.$delete(this.survey.questions, index);
    },

    submit () {
      this.$api.request({
        url: '/admin/surveys' + (this.survey.id ? '/' + this.survey.id : ''),
        method: this.survey.id ? 'put' : 'post',
        data: this.survey,
      }).then(response => {
        this.$toast.fire({
          icon: 'success',
          title: 'Succesvol opgeslagen!'
        });

        this.survey = Object.assign({}, response.data);

      }).catch(error => {
        this.handleErrors(error);
      });

    }
  }
}
</script>
