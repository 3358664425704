<template>
  <div class="training-form">
    <template v-if="loaded">

      <div class="bg-gray-200 p-2 mb-1 small">
        <div class="row align-items-center">
          <div class="col-md-6">
            <form method="get">
              <div class="form-inline">
                Toon
                <select v-model="surveys.per_page" class="form-control form-control-sm mx-2">
                  <option v-for="perPage in [10, 25, 50, 100]" :value="perPage" :selected="surveys.per_page === perPage">{{ perPage }}</option>
                </select>
                per pagina

              </div>
            </form>
          </div>
          <div class="col-sm-6 text-right d-none">
            <a href="#" class="btn btn-sm btn-light ml-4">Filters (0)</a>
          </div>
        </div>
      </div>

      <b-table striped hover
               :items="surveys.data"
               :fields="columns"
               :busy="loading"
               :no-local-sorting="true"
               :sort-by.sync="sorting.column"
               :sort-desc.sync="sorting.desc"
               head-variant="light"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner />
          </div>
        </template>

        <template #cell(buttons)="row">
          <div class="text-right">
            <a :href="'/enquete/' + row.item.id" class="btn btn-light btn-sm">{{ $t('Bekijk enquete') }}</a>
            <a :href="'/admin/surveys/' + row.item.id + '/submissions'" class="btn btn-light btn-sm">{{ $t('Inzendingen') }}</a>
            <a :href="'/admin/surveys/' + row.item.id + '/edit'" class="btn btn-light btn-sm">{{ $t('Bewerk') }}</a>
            <div @click="destroy(row.item.id)" class="btn btn-danger btn-sm">{{ $t('Verwijder') }}</div>
          </div>
        </template>

      </b-table>

      <div class="bg-gray-200 p-2 mt-1 mb-4 small">
        <div class="row align-items-center">
          <div class="col-md-6">
          </div>
          <div class="col-sm-6 text-right">
            Totaal: {{ surveys.total }} enquetes
          </div>
        </div>
      </div>

      <b-pagination
          v-model="surveys.current_page"
          :total-rows="surveys.total"
          :per-page="surveys.per_page"
          align="center"
      ></b-pagination>

    </template>
    <template v-else>
      <b-skeleton-table
          :table-props="{ striped: true, hover: true, tableVariant: 'light' }"
          :columns="columns.length" />
    </template>
  </div>
</template>

<script>
export default {
  name: 'ScreensSurveysOverview',

  data() {
    return {
      surveys: [],
      loaded: false,
      loading: false,
      sorting: {
        column: 'id',
        desc: false,
      },
      filters: {},
      columns: [
        {
          key: 'name',
          label: 'Naam',
          sortable: true,
        },
        {
          key: 'buttons',
          label: '',
        }
      ]
    }
  },
  watch: {
    'surveys.current_page' (page) {
      this.loading = true;
      this.getSurveys(page).then(() => {
        this.loading = false;
      });
    },
    'surveys.per_page' (page) {
      this.loading = true;
      this.getSurveys().then(() => {
        this.loading = false;
      });
    },
    sorting: {
      handler() {
        this.loading = true;
        this.getSurveys().then(() => {
          this.loading = false;
        });
      },
      deep: true,
    }
  },
  mounted() {

    this.$api.all([
      this.getSurveys()
    ]).then(() => {
      this.loaded = true
    })
  },

  methods: {
    filter(page = 0) {
      this.loading = true;
      this.getSurveys().then(() => {
        this.loading = false;
      });
    },

    destroy(id) {
      Confirm().then(result => {
        if (result.isConfirmed) {
          this.$api.delete('/admin/surveys/' + id).then(response => {
            this.$toast.fire({
              icon: 'success',
              title: 'Succesvol verwijderd'
            });
            this.getSurveys()
          });
        }
      });
    },

    getSurveys(page = 0) {
      return this.$api.get('/admin/surveys/', { params: { page, per_page: this.surveys.per_page, sort: this.sorting.column, desc: this.sorting.desc, filters: { ...this.filters } } }).then(response => {
        this.surveys = response.data
      })
    },
  }
}
</script>
