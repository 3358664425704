<script>
export default {
  methods: {
    hasErrors () {
      return Object.keys(this.errors).length > 0
    },

    handleErrors (error) {
      this.submitting = false

      if (error && error.response && error.response.status === 422) {
        this.errors = error.response.data.errors || null
      } else if (error.response && error.response.status !== 422 && error.response.data) {
        let message = this.$t('Something went wrong, please try again. If the problem persists, please contact us for assistance.');

        if (error.response.data.hasOwnProperty('errors')) {
          message = Array.isArray(error.response.data.errors) ? error.response.data.errors.join('\n') : error.response.data.errors;
        }
        Swal.fire({
          title: this.$t('Notification'),
          text: message,
          icon: 'error'
        })
      }
    }
  }
}
</script>