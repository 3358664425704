<template>
    <div :id="'files-' + id || ''">
        <template>
            <div class="dropzone mb-2" :id="'uploader-' + id || ''">
                <div class="dz-message">
                    {{ $t('Sleep bestanden hier of klik om te uploaden.') }}
                </div>
            </div>

            <table v-if="files.length > 0" class="table table-striped">
                <tr v-for="(file, index) in files">
                    <td>
                        {{ file.original_filename }}
                        <input type="hidden" :name="name" :value="file.hash"/>
                    </td>
                    <td>{{ file.extension }}</td>
                    <td>{{ new Intl.DateTimeFormat('nl', {
                        timeStyle: "medium",
                        dateStyle: "short"
                        }).format(new Date(file.created_at)) }}</td>
                    <td>
                        <a href="#" class="btn btn-primary btn-sm" v-on:click.prevent="removeFile(index)">{{ $t('Verwijderen') }}</a>
                        <a :href="file.url" class="btn btn-primary btn-sm" download>{{ $t('Download') }}</a>
                    </td>
                </tr>
            </table>



            <div class="alert alert-warning" v-else>{{ $t('Geen bestanden geupload') }}</div>
        </template>
    </div>
</template>

<script>
  // Dropzone
  window.Dropzone = require('dropzone');
  Dropzone.autoDiscover = false;

  export default {
    data: function () {
      return {
        files: [],
        upload: true,
        dropzone: null,
        name: (this.$attrs.name ? this.$attrs.name + '[]' : 'files[]')
      }
    },
    props: {
      id: { type: Number | String, default: '' },
      dropzoneOptions: {
        type: Object,
        default: () => {
          return { //
            maxFilesize: null
          }
        },
      },
      limit: {
        type: Number,
        default: -1,
      },
    },
    methods: {
      removeFile: function (index) {
        let self = this;
        window.Confirm().then(function (result) {
          if (result.value) {
            self.$delete(self.files, index);
          }
        });
      }
    },
    mounted() {
      let self = this;

        this.dropzone = new Dropzone('#uploader-' + this.id || '', {
          timeout: 30000000,
          url: '/api/files/upload-media',
          addRemoveLinks: true,
          headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
          },
          init: function () {
            let dz = this;
            this.on('error', function (file, response) {
              $(file.previewElement).find('.dz-error-message').text(response.msg);
            });
            this.on('success', function (file, response) {
              self.files.push(response.file);
              dz.removeFile(file);
            });
          },
          ...this.dropzoneOptions
        });

        if (this.$attrs.hasOwnProperty('files')) {
            this.files = this.$attrs.files;
        }
    },
    created() {

    },
  }
</script>
