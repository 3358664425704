require('./bootstrap');
require('./plugins/includes');
require('./plugins/api');
require('./plugins/i18n');
require('./plugins/confirm-dialog')

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => {
  let name = key.split('/').splice(2)
  name[name.length - 1] = name[name.length - 1].split('.')[0]
  Vue.component(_.map(name, _.upperFirst).join(''), files(key).default)
})

const app = new Vue({
  el: '#app',
  i18n: i18n,
});
