import VueI18n from 'vue-i18n'

window.VueI18n = VueI18n;
let language = App.Language;

window.i18n = new VueI18n({
  locale: language, // set locale
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  formatFallbackMessages: true,
  messages: [],
});
